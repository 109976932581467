.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #000000;
}

.nav-pills .nav-link {
  border-radius: 0.1875rem;
}

.nav-link {
  padding: 0.5rem 1rem;
  color: #000000;
}

.qr-code-container {
  position: relative;
}

.d-grid {
  display: grid; /* Ensure it's a grid container */
  justify-items: center; /* Centers items horizontally */
}

.text-overlay {
  position: absolute;
  bottom: 5px; /* Adjust for positioning */
  z-index: 1050;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  font-size: 8px; /* Adjust as needed */
  text-align: center; /* Center text */
}

.qr-code {
  max-width: 500px;
  max-height: 500px;
  position: sticky;
  z-index: 1050;
  top: 5px;
  padding: 35px; /* This can be adjusted based on desired spacing */
  background-color: transparent; /* Remove the grey background */
  /* border-radius: 50%;  Maintain circular shape */
  /* border: 5px solid black;  Add a black border */
}


.qr-code canvas {
  max-width: 300px !important;
  width: 100%;
  padding: 1rem;
}

.text-container {
  position: absolute;
  bottom: 10px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%); /* Center the text */
  background: white; /* Background for text visibility */
  padding: 5px 10px; /* Padding around the text */
  border-radius: 10px; /* Rounded corners for text background */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
}

.offcanvas-start {
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out !important;
  width: 70vw;
}

.btn-success {
  background-color: #42ba96;
  border-color: #42ba96;
  box-shadow: none;
  color: #fff;
}

.btn-primary,
.btn-outline-primary {
  color: #000000;
  border-color: #000000;
}

.btn-primary,
.btn-outline-primary:hover {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.rainbow-button {
  background: linear-gradient(90deg, #cc0000, #cc7f00, #cccc00, #007f00, #007f7f, #00007f, #5c00cc);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 24px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  transition: background 0.3s, transform 0.2s;
}

.rainbow-button:hover {
  transform: scale(1.05);
  opacity: 0.9;
}

hr {
  background-color: #a2a2a2;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.form-check {
  display: flex;
  align-items: center;
}

.form-switch .form-check-input {
  width: 40px;
  height: 20px;
  margin-right: 1rem;
}

.disabled {
  pointer-events: none;
  opacity: 0.65;
}
